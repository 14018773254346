<template>
  <v-app>
    <v-container id="container" fluid>
      <v-row
        align="center"
        justify="center"
        class="mt-10"
        style="height: 250px"
      >
        <div class="text-center">
          <div class="text-center">
            <v-img
              id="imgbadrequest"
              min-width="300"
              max-width="650"
              class="ml-4"
              :src="badrequest"
            ></v-img>
            <h3>{{ message }}</h3>
          </div>
        </div>
      </v-row>
      <v-row id="btn" align="end" justify="center" class="mt-10">
        <v-btn elevation="5" @click="$router.go(-1)" color="accent">
          Voltar para a página anterior
        </v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "PageError",
  data() {
    return {
      badrequest: require("@/assets/images/400pageError.png"),
      code: 400,
      message:
        "Ops! Sua solicitação é inválida e não pôde ser processada pelo servidor, verifique suas permissões de acesso e tente novamente mais tarde.",
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Press+Start+2P&display=swap");
#btn {
  height: 50% !important;
}

h3 {
  font-family: "Open Sans", sans-serif;
  color: rgb(49, 31, 80);
  margin: 30px;
}

#container {
  background: rgb(49, 31, 80);
  background: linear-gradient(
    106deg,
    rgba(49, 31, 80, 0.6628852224483543) 0%,
    rgba(255, 255, 255, 1) 48%
  );
  height: 100%;
}

#imgbadrequest {
  animation: myAnim 2s linear 0s infinite normal forwards;
  margin: auto !important;
}

@keyframes myAnim {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@media (max-width: 650px) {
  #imgbadrequest {
    width: 480px !important;
  }
  h3 {
    font-size: larger !important;
  }
}

@media (max-width: 550px) {
  #imgbadrequest {
    width: 330px !important;
  }
  h3 {
    font-size: medium !important;
  }
}
</style>
