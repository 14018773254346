import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=02ff0d10&scoped=true"
import script from "./PageError.vue?vue&type=script&lang=js"
export * from "./PageError.vue?vue&type=script&lang=js"
import style0 from "./PageError.vue?vue&type=style&index=0&id=02ff0d10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ff0d10",
  null
  
)

export default component.exports